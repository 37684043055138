class Model {
  /**
   * Constructor.
   *
   * @param {Object} fields - Fields configuration for this model.
   */
  constructor(fields) {
    this.fields = fields;
  }

  label(key) {
    return this.fields[key].label();
  }

  labelClass(key) {
    return this.fields[key].labelClass();
  }

  labelInnerClass(key) {
    return this.fields[key].labelInnerClass();
  }

  value(key) {
    return this.fields[key].value(this[key]);
  }

  valueClass(key) {
    return this.fields[key].valueClass(this[key]);
  }

  valueInnerClass(key) {
    return this.fields[key].valueInnerClass(this[key]);
  }
}

module.exports = {
  Model,
};
