<template>
  <div class="h-full">
    <nav-section />

    <router-view />

    <footer-section />
  </div>
</template>

<script>
import get from 'lodash/get';
import clone from 'lodash/clone';
import { RouterView } from 'vue-router';

import NavSection from './components/NavSection';
import FooterSection from './components/FooterSection';
import appConfig from '#config/app';

export default {
  name: 'App',

  components: {
    RouterView,
    NavSection,
    FooterSection,
  },

  /**
   * Global component error handler.  If the error comes from a component that has an apiErrors
   * object, it attempts to set the apiErrors from any validation response.  Otherwise, it just
   * logs the error to the console.
   *
   * @param {Error} error - The error that was captured.
   * @param {Object} vm - The Vue component that triggered the error.
   */
  errorCaptured(error, vm) {
    return this.parseError(error, vm);
  },

  methods: {
    /**
     * Gets API error messages from an error.  Attempts to retrieve error message from response,
     * but if the error comes from some other source (coding error, etc), it will log the error and
     * return the provided apiErrors unchanged.
     *
     * @param {Error} error - The error to get the message from.
     * @param {Object} apiErrors - The existing apiErrors.
     *
     * @return {Object}
     */
    getApiErrors(error, apiErrors) {
      const errors = clone(apiErrors);

      if (get(error, 'response.body.errors', false)) {
        get(error, 'response.body.errors', []).forEach((apiError) => {
          // If no source parameter provided, display error as a toast
          if (get(apiError, 'source.parameter', 'toast') === 'toast') {
            this.$toasted.show(apiError.title, {
              type: 'error',
              icon: 'exclamation-circle',
            });
          }
          // Otherwise, add it to the error array for the specified paramter
          else {
            errors[apiError.source.parameter].push(apiError);
          }
        });
      }
      // Fail-safe error message
      else {
        console.error(error); // eslint-disable-line no-console
        this.$toasted.show(appConfig.defaultErrorMessage, {
          type: 'error',
          icon: 'exclamation-circle',
        });
      }

      return errors;
    },

    /**
     * Parse a captured error.
     *
     * @param {Error} error - The error that was captured.
     * @param {Object} vm - The Vue component that triggered the error.
     */
    parseError(error, vm) {
      if (error.status === 401) {
        // Clear all toasts
        this.$toasted.toasts.forEach((toast) => toast.goAway(0));
        this.$toasted.show('Unauthorized, please log in.', {
          type: 'error',
          icon: 'exclamation-circle',
        });
      }
      // Error provided from our API
      else if (get(vm, 'apiErrors', false)) {
        vm.apiErrors = this.getApiErrors(error, vm.apiErrors);
      }
      // Misc error
      else {
        console.error(error); // eslint-disable-line no-console
        this.$toasted.show(appConfig.defaultErrorMessage, {
          type: 'error',
          icon: 'exclamation-circle',
        });
      }

      return false;
    },
  },
};
</script>

<style>
</style>
