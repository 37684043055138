const { fromPairs, get, trim } = require('lodash');
const superagent = require('superagent-use')(require('superagent'));

const request = superagent.agent();

/**
 * Update CSRF token to use in all requests.
 *
 * @param {String} csrf - The CSRF token to update with.
 */
request.updateCsrf = (csrf) => {
  request.use((req) => {
    req.set('X-CSRF-TOKEN', csrf);

    return req;
  });
};

// Get CSRF token from cookies, if set
const cookies = fromPairs(
  document.cookie.split(';').map((cookie) => cookie.split('=').map(trim)),
);
request.updateCsrf(get(cookies, 'csrf', ''));

// If not authorized, redirect to login or provided redirect
request.on('response', (res) => {
  if (res.statusCode === 401 && window.vue.$router.history.current.fullPath !== '/login') {
    window.vue.$router.push(get(res.body, 'redirect_to', '/login'));
  }
});

module.exports = { request };
