const Stripe = require('stripe');
const WoodStripe = require('@wood/lib/Stripe');

const PAYMENT_REQUIRES_ACTION = 'requires_action';
const PAYMENT_SUCCEEDED = 'succeeded';

const ACTION_REQUIRES_SDK = 'use_stripe_sdk';

/**
 * Creates a PaymentIntent for the provided amount/currency.
 *
 * @param {Number} amount - The amount to create the payment intent for (in cents).
 * @param {String} paymentMethodId - The ID of the payment method being used.
 * @param {String} email - The email address to send a payment receipt for.
 *
 * @return {PaymentIntent}
 */
async function createPaymentIntent(amount, paymentMethodId, email) {
  const stripe = Stripe(process.env.STRIPE_SK); // eslint-disable-line new-cap

  return stripe.paymentIntents.create({
    payment_method: paymentMethodId,
    amount,
    currency: 'usd',
    confirmation_method: 'manual',
    confirm: true,
    receipt_email: email,
    description: 'You paid the most!',
  });
}

/**
 * Confirm a payment intent.
 *
 * @param {String} paymentIntentId - The ID of the payment intent to confirm.
 * @return {[type]}                 [description]
 */
async function confirmPaymentIntent(paymentIntentId) {
  const stripe = Stripe(process.env.STRIPE_SK); // eslint-disable-line new-cap

  return stripe.paymentIntents.confirm(paymentIntentId);
}

module.exports = {
  ...WoodStripe,
  createPaymentIntent,
  confirmPaymentIntent,

  PAYMENT_REQUIRES_ACTION,
  PAYMENT_SUCCEEDED,
  ACTION_REQUIRES_SDK,
};
