<template>
  <div class="w-full container mx-auto">
    <div class="w-full flex items-center justify-between">
      <router-link
        class="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
        to="/"
      >
          I Paid The <span class="pl-2 bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 via-yellow-50 to-yellow-400">Most</span>
      </router-link>

      <div class="flex w-1/2 justify-end content-center">
        <router-link
          to="/"
          v-if="currentPage && currentPage !== 'HomePage'"
          class="text-indigo-400 px-4 hover:underline"
        >
          Okay
        </router-link>

        <span v-if="currentPage && currentPage !== 'HomePage'">|</span>

        <router-link
          to="/what"
          v-if="currentPage !== 'WhatPage'"
          class="text-indigo-400 px-4 hover:underline"
        >
          What
        </router-link>
        <span
          v-else
          class="text-gray-400 px-4"
        >
          What
        </span>

        |

        <router-link
          to='/history'
          v-if="currentPage !== 'HistoryPage'"
          class="text-indigo-400 px-4 hover:underline"
        >
          Payment History
        </router-link>
        <span
          v-else
          class="text-gray-400 px-4"
        >
          Payment History
        </span>

      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: 'NavSection',

  components: {
    RouterLink,
  },

  data: () => ({
    currentPage: '',
  }),

  watch: {
    /**
     * Watch when the route changes, so we can update the "WhatLink".
     *
     * @param {Object} to - The route that has just been navigated to.
     */
    async $route(to) {
      this.currentPage = to.name;
    },
  },
};
</script>
