/* eslint-disable max-classes-per-file */

const moment = require('moment');
const numbro = require('numbro');

/**
 * The base class for field display classes.
 */
class FieldDisplay {
  /**
   * Constructor.
   *
   * @param {String} label - The label of the field to display.
   * @param {Array} labelClasses - Extra CSS classes to apply to label containers.
   * @param {Array} labelInnerClasses - Extra CSS classes to apply to text inside containers.
   * @param {Array} valueClasses - Extra CSS classes to apply to value containers.
   * @param {Function} valueClassFn - A function that can calculate per-value classes for
   *                                  containers.
   * @param {Array} valueInnerClasses - Extra CSS classes to apply to text inside containers.
   * @param {Function} valueInnerClassFn - A function that can calculate per-value classes for text
   *                                       inside containers.
   */
  constructor({
    label,
    labelClasses = [],
    labelInnerClasses = [],
    valueClasses = [],
    valueClassFn,
    valueInnerClasses = [],
    valueInnerClassFn,
  } = {}) {
    this.labelText = label;

    this.labelClasses = labelClasses;
    this.labelInnerClasses = labelInnerClasses;

    this.valueClasses = valueClasses;
    this.valueClassFn = valueClassFn;

    this.valueInnerClasses = valueInnerClasses;
    this.valueInnerClassFn = valueInnerClassFn;
  }

  /**
   * By default, just display the label.
   *
   * @return {String}
   */
  label() {
    return this.labelText;
  }

  /**
   * By default, just display the raw value.
   *
   * @param {String} value - The value to display.
   *
   * @return {String}
   */
  value(value) {
    return value;
  }

  /**
   * Return the classes for the label container for this field, joined by a space.
   *
   * @return {String}
   */
  labelClass() {
    return this.labelClasses.join(' ');
  }

  /**
   * Return the classes for the label text for this field, joined by a space.
   *
   * @return {String}
   */
  labelInnerClass() {
    return this.labelInnerClasses.join(' ');
  }

  /**
   * Return the classes for the value container for this field, joined by a space.
   *
   * If a custom value class function has been defined for this field, use it instead.
   *
   * @param {String} value - The value for this field, may be used to modify the classes.
   *
   * @return {String}
   */
  valueClass(value) {
    return this.valueClassFn
      ? this.valueClassFn(value)
      : this.valueClasses.join(' ');
  }

  /**
   * Return the classes for the value text inside the container for this field, joined by a space.
   *
   * If a custom value class function has been defined for this field, use it instead.
   *
   * @param {String} value - The value for this field, may be used to modify the classes.
   *
   * @return {String}
   */
  valueInnerClass(value) {
    return this.valueInnerClassFn
      ? this.valueInnerClassFn(value)
      : this.valueInnerClasses.join(' ');
  }
}

/**
 * Displays the field without modification.
 */
class FieldString extends FieldDisplay { }

/**
 * Displays the field as a formatted number.
 */
class FieldNumber extends FieldDisplay {
  /**
   * Constructor.
   *
   * @param {String} numberFormat - The number format. (https://numbrojs.com/format.html)
   * @param {Object} options - Remaining options to pass to super.
   */
  constructor({ numberFormat, ...options } = {}) {
    super(options);
    this.numberFormat = numberFormat;
  }

  /**
   * Replace the value with a formatted number value.
   *
   * @param {String} value - The value to replace.
   *
   * @return {String}
   */
  value(value) {
    return numbro(value).format(this.numberFormat);
  }
}

/**
 * Displays the field as a currency.
 */
class FieldCurrency extends FieldDisplay {
  /**
   * Constructor.
   *
   * @param {String} currencyFormat - The currency format. (https://numbrojs.com/format.html#currency)
   * @param {Object} options - Remaining options to pass to super.
   */
  constructor({ currencyFormat, ...options } = {}) {
    super(options);
    this.currencyFormat = currencyFormat;
  }

  /**
   * Replace the value with a formatted currency.
   *
   * @param {String} value - The value to replace.
   *
   * @return {String}
   */
  value(value) {
    return numbro(value).formatCurrency(this.currencyFormat);
  }
}

class FieldBoolean extends FieldDisplay {
  /**
   * Constructor.
   *
   * @param {String} trueValue - The value to display when true.
   * @param {String} falseValue - The value to display when false.
   * @param {Object} options - Remaining options to pass to super.
   */
  constructor({ trueValue = 'Yes', falseValue = 'No', ...options } = {}) {
    super(options);
    this.trueValue = trueValue;
    this.falseValue = falseValue;
  }

  /**
   * Replace the value with a string representation.
   *
   * @param {boolean} value - The value to replace.
   *
   * @return {String}
   */
  value(value) {
    return value ? this.trueValue : this.falseValue;
  }
}

/**
 * Displays a formatted date.
 */
class FieldDate extends FieldDisplay {
  /**
   * Constructor.
   *
   * Default date format: "Jan 25 2020, 10:24:59 pm"
   *
   * @param {String} dateFormat - The date format. (https://momentjs.com/docs/#/displaying/format)
   * @param {String} nullValue - The value to display if null.
   * @param {Object} options - Remaining options to pass to super.
   */
  constructor({ dateFormat = 'MMM D YYYY, h:mm:ss a', nullValue = '-', ...options } = {}) {
    super(options);
    this.dateFormat = dateFormat;
    this.nullValue = nullValue;
  }

  /**
   * Replace the value with a formatted date value.
   *
   * @param {String} value - The value to replace.
   *
   * @return {String}
   */
  value(value) {
    return value
      ? moment(value).format(this.dateFormat)
      : this.nullValue;
  }
}

/**
 * Displays an enumerated list of values.
 */
class FieldEnum extends FieldDisplay {
  /**
   * Constructor.
   *
   * @param {Object} enumList - The enumeration list of keys and their display values.
   * @param {Object} options - Remaining options to pass to super.
   */
  constructor({ enumList, ...options } = {}) {
    super(options);
    this.enumList = enumList;
  }

  /**
   * Replace the value with the defined enum value.
   *
   * @param {String} value - The value to replace.
   *
   * @return {String}
   */
  value(value) {
    return this.enumList[value];
  }
}

/**
 * Calculates the value with a custom value function.
 */
class FieldCustom extends FieldDisplay {
  /**
   * Constructor.
   *
   * @param {Function} valueFn - The custom function to calculate the value with.
   * @param {Object} options - Remaining options to pass to super.
   */
  constructor({ valueFn, ...options } = {}) {
    super(options);
    this.valueFn = valueFn;
  }

  /**
   * Replace the value with the one calculated from the custom function..
   *
   * @param {String} value - The value to replace.
   *
   * @return {String}
   */
  value(value) {
    return this.valueFn(value);
  }
}

module.exports = {
  FieldDisplay,
  FieldNumber,
  FieldCurrency,
  FieldString,
  FieldBoolean,
  FieldDate,
  FieldEnum,
  FieldCustom,
};
