const { get } = require('lodash');
const { request } = require('#ui/lib/xhr');
const { delayMin } = require('#lib/Time');
const { PaymentModel } = require('#features/payments/lib/models/PaymentModel');
const { ERROR_AMOUNT_ALREADY_PAID } = require('#lib/Errors');
const {
  PAYMENT_REQUIRES_ACTION,
  PAYMENT_SUCCEEDED,
} = require('#lib/Stripe');

module.exports = {
  namespaced: true,

  state: {
    /**
     * @type {PaymentModel} The highest payment.
     */
    theMost: null,

    /**
     * @type {Array} The current page of the list of payments being viewed.
     */
    currentPage: [],

    /**
     * @type {Number} The total number of pages of payments.
     */
    totalPages: 1,
  },

  mutations: {
    /**
     * Save the list of payments.
     *
     * @param {Object} state - The state to modify.
     * @param {Array<PaymentModel>} payments - The payments to save.
     * @param {Number} pages - The total number of pages of payments.
     */
    saveList(state, { payments, pages }) {
      state.currentPage = payments;
      state.totalPages = pages;
    },

    /**
     * Store the highest payment.
     *
     * @param {Object} state - The state to modify.
     * @param {PaymentModel} payment - The payment to save.
     */
    saveTheMost(state, { payment }) {
      state.theMost = payment;
    },

    /**
     * Add a payment to the current page of the list.
     *
     * @param {Object} state - The state to modify.
     * @param {PaymentModel} payment - The payment to add.
     */
    addPayment(state, { payment }) {
      state.currentPage.push(payment);
    },
  },

  actions: {
    /**
     * Make a payment.
     *
     * @param {Object} storeState - The store's current state.
     * @param {Function} commit - Function used to call mutation.
     * @param {Stripe} stripe - The instance of Stripe to use to complete the purchase.
     * @param {Object} card - The Stripe card to charge.
     * @param {Boolean} sendMoreAgree - If the user agrees to be emailed when someone else pays more
     * @param {String} name - The person's name.
     * @param {String} email - The person's email.
     * @param {String} social - The person's social (www, twitter, etc)
     * @param {String} message - The message they wish to leave.
     * @param {String} line1 - The address of the card to charge.
     * @param {String} country - The country of the card to charge.
     * @param {String} state - The state of the card to charge.
     * @param {String} city - The city of the card to charge.
     * @param {String} postal_code - The postal code of the card to carge.
     */
    async pay(
      { state: storeState, commit },
      {
        stripe, card, sendMoreAgree,
        name, email, social, message,
        line1, country, state, city, postal_code,
      },
    ) {
      const payment = new PaymentModel({
        name, email, social, message, amount: storeState.theMost.getNextAmountCents(),
      });

      const stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name,
          email,
          address: { line1, country, state, city, postal_code },
        },
      });

      if (stripeResponse.error) {
        console.error(stripeResponse.error);

        window.vue.$toasted.show('Could not process payment at this time.', {
          type: 'error',
          icon: 'exclamation-circle',
          duration: null,
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }

      try {
        const apiResponse = await request.post('/api/public/payments').send({
          paymentMethodId: stripeResponse.paymentMethod.id,
          send_more_agree: sendMoreAgree,
          ...payment.toJSON(),
        });

        const response = await handleApiResponse(apiResponse, payment, stripe, sendMoreAgree);

        if (response.success) {
          commit('saveTheMost', { payment });
        }

        return response;
      }
      catch (error) {
        if (get(error, 'response.body.errors', false)) {
          get(error, 'response.body.errors', []).forEach((apiError) => {
            if (apiError.code === ERROR_AMOUNT_ALREADY_PAID) {
              commit('saveTheMost', { payment: new PaymentModel(apiError.meta.newTheMost) });

              window.vue.$toasted.show(apiError.title, {
                type: 'error',
                icon: 'exclamation-circle',
                duration: null,
                action: {
                  text: 'Close',
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  },
                },
              });
            }
          });
        }

        throw error;
      }
    },

    /**
     * Get the list of payments.
     *
     * @param {Function} commit - Function used to call mutation.
     * @param {Number} page - Page of list to show.
     * @param {Number} per - The number of entries to show per page.
     * @param {String} search - The text to search for.
     */
    async getList({ commit }, { page = 1, per = 20, search = '' } = {}) {
      const { body: { data, meta } } = await delayMin(
        500,
        request.get('/api/public/payments').query({ page, per, search }),
      );

      commit('saveList', {
        payments: data.payments.map((payment) => new PaymentModel(payment)),
        pages: meta.pages,
      });
    },

    /**
     * Get the highest payment.
     *
     * @param {Function} commit - Function used to call mutation.
     */
    async getTheMost({ commit }) {
      const { body: { data } } = await delayMin(
        500,
        request.get('/api/public/payments').query({ page: 1, per: 1 }),
      );

      commit('saveTheMost', { payment: new PaymentModel(data.payments[0]) });
    },
  },
};

/**
 * Handle the response from the attempt to make a payment on the server.
 *
 * @param {Object} response - The response from the server
 * @param {ModelPayment} payment - The payment to send to the server.
 * @param {Stripe} stripe - The instance of Stripe to use if we need to handle a card action.
 * @param {Boolean} sendMoreAgree - If the user agrees to be emailed when someone else pays more
 */
async function handleApiResponse(apiResponse, payment, stripe, sendMoreAgree) {
  const { body: { data } } = apiResponse;

  if (data.result === PAYMENT_SUCCEEDED) {
    return {
      success: true,
    };
  }

  if (data.result === PAYMENT_REQUIRES_ACTION) {
    const stripeResponse = await stripe.handleCardAction(data.client_secret);

    if (stripeResponse.error) {
      return {
        success: false,
        message: stripeResponse.error.message,
      };
    }

    const newApiResponse = await request.post('/api/public/payments').send({
      paymentIntentId: stripeResponse.paymentIntent.id,
      send_more_agree: sendMoreAgree,
      ...payment.toJSON(),
    });

    return handleApiResponse(newApiResponse, payment, stripe, sendMoreAgree);
  }

  return {
    success: false,
    message: 'Could not submit card.', // Update with Stripe error message later
  };
}
