const { Model } = require('#lib/Model');
const { FieldString, FieldCurrency, FieldCustom } = require('#lib/Fields');

/**
 * @type {Object} Field configuration.
 */
const FIELDS = {
  name: new FieldString({ label: 'Name' }),
  social: new FieldCustom({
    label: 'Social',
    valueFn: (value) => ((! value || value.startsWith('http')) ? value : `http://${value}`),
  }),
  message: new FieldString({ label: 'Message' }),
  email: new FieldString({ label: 'Email' }),
  amountDollars: new FieldCurrency({
    label: 'Amount',
    currencyFormat: { thousandSeparated: true, mantissa: 2 },
  }),
};

class PaymentModel extends Model {
  /**
   * Constructor.
   *
   * @param {Number} id - The ID of this model.
   * @param {String} name - The name of the purchaser.
   * @param {String} social - The purchaser's insta/twitter/website/etc.
   * @param {String} message - The message the purchaser wanted to leave.
   * @param {String} email - The purchaser's email address.
   * @param {Number} amount - The payment amount, in cents.
   * @param {Boolean} send_more_agree - If the user agrees to get email when someone else pays more.
   */
  constructor({ id, name, social, message, email, amount, send_more_agree } = {}) {
    super(FIELDS);

    this.id = id;
    this.name = name;
    this.social = social;
    this.message = message;
    this.email = email;
    this.amountCents = amount;
    this.amountDollars = amount / 100;
    this.sendMoreAgree = send_more_agree;
  }

  /**
   * Convert model to JSON.
   *
   * @return {Object}
   */
  toJSON() {
    return {
      id: this.id,
      name: this.name,
      social: this.social,
      message: this.message,
      email: this.email,
      amount: this.amountCents,
      send_more_agree: this.sendMoreAgree,
    };
  }

  /**
   * Get the amount of the new highest payment, in cents.
   *
   * @return {Number}
   */
  getNextAmountCents() {
    // Less than $5 -> $0.05 increment
    if (this.amountCents < 500) {
      return this.amountCents + 5;
    }

    // Less than $10 -> $0.25 increment
    if (this.amountCents < 1000) {
      return this.amountCents + 25;
    }

    // Less than $50 -> $1 increment
    if (this.amountCents < 5000) {
      return this.amountCents + 100;
    }

    // Less than $100 -> $2 increment
    if (this.amountCents < 10000) {
      return this.amountCents + 200;
    }

    // Less than $500 -> $5 increment
    if (this.amountCents < 50000) {
      return this.amountCents + 500;
    }

    // Less than $1000 -> $10 increment
    if (this.amountCents < 100000) {
      return this.amountCents + 1000;
    }

    // More than $1000 -> $25.00 increment
    return this.amountCents + 2500;
  }
}

module.exports = {
  PaymentModel,
  FIELDS,
};
