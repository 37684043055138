import Vue from 'vue';
import App from './App.vue';
import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal'
import './assets/tailwind.css';
import router from './router';
import store from './store';

Vue.config.productionTip = true;
Vue.use(Toasted, {
  position: 'bottom-left',
  duration: 5000, // Default to 5 second duration, can be overridden
  iconPack: 'fontawesome',
  keepOnHover: true,
});
Vue.use(VModal);

window.vue = new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
});
