const WoodErrors = require('@wood/lib/Errors');

module.exports = {
  ...WoodErrors,

  // The amount attempting to pay has already been paid
  ERROR_AMOUNT_ALREADY_PAID: 'ERROR_AMOUNT_ALREADY_PAID',

  // User is trying to pay a non-standard amount
  ERROR_INVALID_AMOUNT: 'ERROR_INVALID_AMOUNT',

  // The value is not a valid string
  ERROR_INVALID_URL: 'ERROR_INVALID_URL',
};
