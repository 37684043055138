import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../pages/HomePage.vue'),
  },
  {
    path: '/what',
    name: 'WhatPage',
    component: () => import('../pages/WhatPage.vue'),
  },
  {
    path: '/history',
    name: 'HistoryPage',
    component: () => import('../pages/HistoryPage.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
