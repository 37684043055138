import Vue from 'vue';
import Vuex from 'vuex';
import Payments from '#features/payments/ui/stores/PaymentsStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Payments,
  },
});
